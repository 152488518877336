<template>
  <div class="flex flex-col px-1">
    <div class="sticky top-0 z-20 text-2xl pt-2 pb-2 bg-white border-b border-gray-400">
      Hilfe-Überschrift {{ page.helpIndex }}
      <i class="fa fa-close float-right cursor-pointer p-1 pr-2" @click="page.toggleHelpActive(page.helpIndex)" />
    </div>

    <div class="flex-grow">
      <HelpBasicBasic v-if="page.helpIndex === pages.HelpBasicBasic" />
      <HelpBasicPowerUsage v-if="page.helpIndex === pages.HelpBasicPowerUsage" />
      <HelpBasicLocation v-if="page.helpIndex === pages.HelpBasicLocation" />
      <HelpBasicPlan v-if="page.helpIndex === pages.HelpBasicPlan" />
      <HelpBasicFloor v-if="page.helpIndex === pages.HelpBasicFloor" />
      <HelpBasicAdditionals v-if="page.helpIndex === pages.HelpBasicAdditionals" />

      <HelpWallsDesignType v-if="page.helpIndex === pages.HelpWallsDesignType" />
      <HelpWallsInsulation v-if="page.helpIndex === pages.HelpWallsInsulation" />
      <HelpWallsOuterWall v-if="page.helpIndex === pages.HelpWallsOuterWall" />
      <HelpWallsInnerWall v-if="page.helpIndex === pages.HelpWallsInnerWall" />

      <HelpFloorBasement v-if="page.helpIndex === pages.HelpFloorBasement" />
      <HelpFloorDesignType v-if="page.helpIndex === pages.HelpFloorDesignType" />
      <HelpFloorInsulation v-if="page.helpIndex === pages.HelpFloorInsulation" />

      <HelpWindowsWall v-if="page.helpIndex === pages.HelpWindowsWall" />
      <HelpWindowsRoof v-if="page.helpIndex === pages.HelpWindowsRoof" />
      <HelpWindowsDoor v-if="page.helpIndex === pages.HelpWindowsDoor" />

      <HelpRoofAtticUsage v-if="page.helpIndex === pages.HelpRoofAtticUsage" />
      <HelpRoofInsulation v-if="page.helpIndex === pages.HelpRoofInsulation" />
      <HelpRoofType v-if="page.helpIndex === pages.HelpRoofType" />

      <HelpTechnologyHeatingType v-if="page.helpIndex === pages.HelpTechnologyHeatingType" />
      <HelpTechnologyPhotovoltaik v-if="page.helpIndex === pages.HelpTechnologyPhotovoltaic" />
      <HelpTechnologyVentilation v-if="page.helpIndex === pages.HelpTechnologyVentilation" />
      <HelpTechnologyWater v-if="page.helpIndex === pages.HelpTechnologyWater" />

      <HelpDetailEfficiencyCurrent v-if="page.helpIndex === pages.HelpDetailEfficiencyCurrent" />
      <HelpDetailPossibilities v-if="page.helpIndex === pages.HelpDetailPossibilities" />
      <HelpDetailEfficiencyAfter v-if="page.helpIndex === pages.HelpDetailEfficiencyAfter" />
    </div>

    <div class="sticky bottom-0 z-20 pb-1 bg-white border-t border-gray-400">
      <div class="text-lg pt-1 pb-1">
        Verwandte Hilfe-Sektionen
      </div>
      <i class="fa fa-external-link"></i> Eintrag A<br>
      <i class="fa fa-external-link"></i> Eintrag B
    </div>
  </div>
</template>

<script>
  import { pageService } from '@/properties/views/components/PropertyRegistration/stores/pageData'
  import pages from '@/properties/views/components/PropertyRegistration/scripts/constants'

  import HelpBasicBasic from '@/properties/views/components/PropertyRegistration/help/basic/Basic.vue'
  import HelpBasicPowerUsage from '@/properties/views/components/PropertyRegistration/help/basic/PowerUsage.vue'
  import HelpBasicLocation from '@/properties/views/components/PropertyRegistration/help/basic/Location.vue'
  import HelpBasicPlan from '@/properties/views/components/PropertyRegistration/help/basic/Plan.vue'
  import HelpBasicFloor from '@/properties/views/components/PropertyRegistration/help/basic/Floor.vue'
  import HelpBasicAdditionals from '@/properties/views/components/PropertyRegistration/help/basic/Additionals.vue'

  import HelpWallsDesignType from '@/properties/views/components/PropertyRegistration/help/walls/DesignType.vue'
  import HelpWallsInsulation from '@/properties/views/components/PropertyRegistration/help/walls/Insulation.vue'
  import HelpWallsOuterWall from '@/properties/views/components/PropertyRegistration/help/walls/OuterWall.vue'
  import HelpWallsInnerWall from '@/properties/views/components/PropertyRegistration/help/walls/InnerWall.vue'

  import HelpFloorBasement from '@/properties/views/components/PropertyRegistration/help/floor/Basement.vue'
  import HelpFloorDesignType from '@/properties/views/components/PropertyRegistration/help/floor/DesignType.vue'
  import HelpFloorInsulation from '@/properties/views/components/PropertyRegistration/help/floor/Insulation.vue'

  import HelpWindowsWall from '@/properties/views/components/PropertyRegistration/help/windows/Wall.vue'
  import HelpWindowsRoof from '@/properties/views/components/PropertyRegistration/help/windows/Roof.vue'
  import HelpWindowsDoor from '@/properties/views/components/PropertyRegistration/help/windows/Door.vue'

  import HelpRoofAtticUsage from '@/properties/views/components/PropertyRegistration/help/roof/AtticUsage.vue'
  import HelpRoofInsulation from '@/properties/views/components/PropertyRegistration/help/roof/Insulation.vue'
  import HelpRoofType from '@/properties/views/components/PropertyRegistration/help/roof/Type.vue'

  import HelpTechnologyHeatingType from '@/properties/views/components/PropertyRegistration/help/technology/HeatingType.vue'
  import HelpTechnologyPhotovoltaik from '@/properties/views/components/PropertyRegistration/help/technology/Photovoltaik.vue'
  import HelpTechnologyVentilation from '@/properties/views/components/PropertyRegistration/help/technology/Ventilation.vue'
  import HelpTechnologyWater from '@/properties/views/components/PropertyRegistration/help/technology/Water.vue'

  import HelpDetailEfficiencyCurrent from '@/properties/views/components/PropertyRegistration/help/details/EfficiencyCurrent.vue'
  import HelpDetailPossibilities from '@/properties/views/components/PropertyRegistration/help/details/EfficiencyPossibilities.vue'
  import HelpDetailEfficiencyAfter from '@/properties/views/components/PropertyRegistration/help/details/EfficiencyAfter.vue'

  export default {
    setup() {
      const page = pageService()

      return { page }
    },
    data() {
      return {
        pages: pages
      }
    },
    components: {
      HelpBasicBasic, HelpBasicPowerUsage, HelpBasicLocation, HelpBasicPlan, HelpBasicFloor, HelpBasicAdditionals,
      HelpWallsDesignType, HelpWallsInsulation, HelpWallsOuterWall, HelpWallsInnerWall,
      HelpFloorBasement, HelpFloorDesignType, HelpFloorInsulation,
      HelpWindowsWall, HelpWindowsRoof, HelpWindowsDoor,
      HelpRoofAtticUsage, HelpRoofInsulation, HelpRoofType,
      HelpTechnologyHeatingType, HelpTechnologyPhotovoltaik, HelpTechnologyVentilation, HelpTechnologyWater,
      HelpDetailEfficiencyCurrent, HelpDetailPossibilities, HelpDetailEfficiencyAfter
    }
  };
</script>