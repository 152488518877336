<template>
    <div class="flex flex-nowrap w-full pb-2 text-lg lg:text-2xl sticky top-0 bg-white z-0">
      <div class="w-3/4">
        <div class="flex flex-nowrap">
          <IconInfo v-if="helpIndex !== 0" class="cursor-pointer my-0.5 lg:my-1"
                    @click="page.toggleHelpActive(helpIndex)"/>
          {{ text }}
        </div>
      </div>
      <div class="w-1/4 text-sm">
        <div class="flex flex-nowrap float-right cursor-pointer text-transparent mt-1 hover:text-black"
             @click="scrollToTop()">
          Seitenanfang
          <IconArrowUp class="w-5 pr-2 ml-2 text-black"/>
        </div>
      </div>
    </div>
</template>

<script>
import {pageService} from '@/properties/views/components/PropertyRegistration/stores/pageData'
import IconInfo from '@/assets/icons/info.svg?inline'
import IconArrowUp from '@/assets/icons/arrow-up-1.svg?inline'

export default {
  setup() {
    const page = pageService()

    return {page}
  },
  components: {
    IconInfo,
    IconArrowUp
  },
  props: {
    text: String,
    helpIndex: Number
  },
  methods: {
    scrollToTop() {
      const element = document.getElementById('mainOutlet')
      if (element) {
        element.scrollTop = 0
      }
    }
  }
};
</script>