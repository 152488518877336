export enum ImageTypes {
    GRUNDRISS,
    SCHNITT,
    LAGEPLAN,
    ANSICHTNORDEN,
    ANSICHTOSTEN,
    ANSICHTSÜDEN,
    ANSICHTWESTEN,
    DRAUFSICHT,
    VORNE,
    HINTEN,
    SEITE,
    FENSTER,
    WAND,
    DACH
}