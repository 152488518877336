<template>
  <span v-if="!picturePreview" class="pointer underline" aria-hidden="true" data-bs-toggle="modal"
        :data-bs-target="'#'+[popUpId]">
    <i class="fa fa-external-link"></i>{{ caption }}
  </span>
  <div v-if="picturePreview && thumbnail" @click="loadImage" class="text-center relative pointer px-4 pb-2"
       aria-hidden="true" data-bs-toggle="modal" :data-bs-target="'#'+[popUpId]">
    <div class="relative inline-block">
      <img v-if="asset" :src="resolve_img_url(thumbnail)" class="card-img-top mx-auto">
      <img v-else :src="[thumbnailImage]" :class="isDisplayImage ? 'opacity-25' : 'opacity-100'"
           class="border border-dark img-fluid">
      <span v-if="isDisplayImage" class="overlay-text absolute top-1/4 left-1/2 text-lg">Hauptbild</span>
    </div>
  </div>

  <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
       :id="[popUpId]" tabindex="-1" :aria-labelledby="[popUpId]+'Label'" aria-hidden="true">
    <div class="modal-dialog relative w-auto pointer-events-none modal-dialog-centered modal-dialog-scrollable">
      <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
        <div
            class="modal-header flex flex-shrink-0 items-center justify-between pl-4 pr-3 py-3 border-b border-gray-200 rounded-t-md">
          <div class="modal-title text-2xl" :id="[popUpId]+'Label'">{{ caption }}</div>
          <i class="fa fa-close fa-2x float-right cursor-pointer" data-bs-dismiss="modal" aria-label="Close"/>
        </div>
        <div class="modal-body relative p-4">
          <img v-if="asset" :src="resolve_img_url(picture)" class="border border-dark img-fluid">
          <img v-else :src="[image]" class="border border-dark img-fluid">
          <loading-circle-animation v-if="thumbnailImage && imageData === ''" class="w-12 h-12 m-4 mx-auto"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingCircleAnimation from "@/common/loading/LoadingCircleAnimation";

export default {
  components: {LoadingCircleAnimation},
  data() {
    return {
      imageData: ''
    }
  },
  props: {
    popUpId: String,
    caption: String,
    asset: Boolean,
    picture: String,
    thumbnail: String,
    picturePreview: Boolean,
    isDisplayImage: Boolean
  },
  methods: {
    resolve_img_url: function (path) {
      if (path != undefined) {
        let images = require.context('../assets/', true, /\.png$|\.jpg$|\.svg$/)
        return images("./" + path)
      }
    },
    async loadImage() {
      const result = await this.$ports.properties.getImage(this.picture);
      if (result) {
        this.imageData = result;
      }
    }
  },
  computed: {
    thumbnailImage() {
      return this.thumbnail;
    },
    image() {
      if (this.imageData === '') {
        return;
      }
      return this.imageData;
    }
  }
};
</script>

<style scoped>
img[class="card-img-top"] {
  min-height: 8em;
  max-height: 8em;
  text-align: center;
  object-fit: contain;
}

.modal-dialog {
  height: 90%;
  max-height: 90%;
}

.modal-content {
  max-height: 90%;
  border-radius: 0;
}

.modal-body > .img-fluid {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fa {
  padding-right: 5px;
}

.pointer {
  cursor: pointer;
}

.overlay-text {
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.25rem; /* Adjust size as needed */
}
</style>